import React, { Component } from 'react'
import classNames from 'classnames'
import TeamModal from '../../General/TeamModal'
import Twitter from '../../Shapes/Twitter'
import Facebook from '../../Shapes/Facebook'
import Instagram from '../../Shapes/Instagram'
import Plus from '../../Shapes/Plus'
import X from '../../Shapes/X'

const nameClasses = `
  text-lg
  font-sans
  font-bold
  mt-2
`

const triangleClasses = `
  green-triangle
  h-0
  w-0
  absolute
  pin-b
  pin-r
`

const modalOverlayClasses = `
  fixed
  pin-t
  pin-l
  z-zeus
  bg-black-trans
  w-full
  h-full
`

const modalWrapClasses = `
  absolute
  w-screen
  h-screen
  md:h-auto
  md:max-w-xl
  mx-auto
  pin-r
  pin-l
  v-center
  z-50
  md:pr-6
`

const modalCloseClasses = `
  absolute
  flex
  items-center
  justify-center
  pin-r
  pin-t
  bg-black
  w-12 h-12
  text-white
  cursor-pointer
`

class PersonCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidUpdate() {
    document.body.classList.toggle('overflow-hidden', this.state.isModalOpen)
    document.querySelector('#site-header').classList.toggle('z-10')
    document.querySelector('#site-footer').classList.toggle('z-10')
    document.querySelector('#site-content').classList.toggle(['z-0', 'z-zeus'])
  }

  toggleModal = (e) => {
    if (!this.props.modalDesc) return
    this.setState((state) => ({ isModalOpen: !state.isModalOpen }))
  }

  render() {
    const { image, name, title, description, modalDesc, social } = this.props

    return (
      <div>
        <div
          className={classNames('relative', {
            'cursor-pointer': modalDesc,
          })}
          onClick={this.toggleModal}
        >
          <img
            className="w-full block"
            src={image.src}
            alt={image.alt}
            copyright={image.copyright}
            id={name.replace(/\s+/g, '-').toLowerCase()}
          />
          {modalDesc && (
            <div className="absolute z-10 pin-r pin-b">
              <div
                className="text-black absolute z-20"
                style={{ bottom: '0.3rem', right: '0.5rem' }}
              >
                <Plus />
              </div>
              <div className={triangleClasses} />
            </div>
          )}
        </div>
        <div className={nameClasses}>{name}</div>
        <div
          className="mt-1 font-sans leading-tight"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {description && (
          <div className="mt-2 leading-relaxed font-sans font-light">
            {description}
          </div>
        )}

        {social && (
          <div className="flex items-center mt-4">
            {social.twitter && (
              <a
                className="mr-2 flex items-center justify-center text-charcoal"
                href={social.twitter}
                target="_blank"
                rel="noopener"
                style={{ width: '25px', height: '25px' }}
              >
                <Twitter />
              </a>
            )}
            {social.facebook && (
              <a
                className="mr-2 flex items-center justify-center text-charcoal"
                href={social.facebook}
                target="_blank"
                rel="noopener"
                style={{ width: '25px', height: '25px' }}
              >
                <Facebook />
              </a>
            )}
            {social.instagram && (
              <a
                className="flex items-center justify-center text-charcoal"
                href={social.instagram}
                target="_blank"
                rel="noopener"
                style={{ width: '25px', height: '25px' }}
              >
                <Instagram />
              </a>
            )}
          </div>
        )}

        {modalDesc && this.state.isModalOpen ? (
          <div className={modalOverlayClasses}>
            <div className={modalWrapClasses}>
              <TeamModal
                image={image}
                name={name}
                title={title}
                bio={modalDesc}
              />
              <div className={modalCloseClasses} onClick={this.toggleModal}>
                <X />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default PersonCard
