import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../utils'
import WysiwygContent from '../../General/WysiwygContent'

// const highlightBarClasses = `
//   h-px
//   bg-magenta
//   my-2
// `

const nameClasses = `
  font-sans
  font-bold
  text-3xl
  text-charcoal
  mb-1
`

const titleClasses = `
  font-sans
  font-normal
  text-2xl
  text-charcoal
`

const UpperContentWrap = styled.div`
  ${ tw`
    md:max-w-xs
    flex-no-shrink
    md:mr-3
    lg:mr-6
  ` }

  height: 40vh;

  ${ screen('md') } {
    height: auto;
  }
`

const UpperImage = styled.img`
  ${ tw`
    w-auto
    h-full
    md:w-full
    md:h-auto
    align-bottom
  ` }
`

const LowerContentWrap = styled.div`
  ${ tw`
    pr-3
    pt-3
    pb-3
    pl-3
    md:pl-0
    lg:pr-5
    lg:pt-5
    lg:pb-4
    ` }

  height: 60vh;
  overflow: auto;

  ${ screen('md') } {
    height: auto;
  }
`

const WysiwygWrap = styled.div`
  ${ screen('md') } {
    height: auto;
    max-height: 350px;
    overflow: auto;
  }
`

const TeamModal = ({ image, name, title, bio }) => (
  <div className="md:flex bg-white h-full">
    <UpperContentWrap>
      <UpperImage
        src={ image.src }
        alt={ image.alt }
        copyright={ image.copyright }
      />
    </UpperContentWrap>
    <LowerContentWrap>
      <div className={ nameClasses }>{ name }</div>
      <div className={ titleClasses } dangerouslySetInnerHTML={{ __html: title }} />
      {/* <div className={ highlightBarClasses } style={ { width: '50px' } } /> */}
      <WysiwygWrap>
        <WysiwygContent content={ bio } />
      </WysiwygWrap>
    </LowerContentWrap>
  </div>
)

export default TeamModal
