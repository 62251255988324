import React from 'react'
import { graphql } from 'gatsby'
import { extract, getPageBgs } from '../utils/graphql'
import { localizePath } from '../../config/i18n'

import TeamLanding from '../components/Templates/TeamLanding'
import NoTranslation from '../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

const transformTeam = (page) => {
  const body = page.data.body
  const executiveRaw = body.find((obj) => obj.primary.executives === 'Yes')
  const executive = {
    intro: {
      title: executiveRaw.primary.section_title.text,
      description: executiveRaw.primary.section_description.text,
      centered: true,
    },
    members: executiveRaw.items.map((member) => ({
      image: {
        src: member.picture.url,
        alt: member.picture.alt,
      },
      name: member.name.text,
      title: member.position.text,
      description: member.description_snippet.text,
      modalDesc: member.description_full.html,
    })),
  }
  const teamsRaw = body.filter((obj) => obj.primary.executives === 'No')
  const teams = teamsRaw.map((team) => {
    return {
      intro: {
        title: team.primary.section_title.text,
        description: team.primary.section_description.text,
        centered: true,
      },
      members: team.items.map((member) => ({
        image: {
          src: member.picture.url,
          alt: member.picture.alt,
        },
        name: member.name.text,
        title: member.position.text,
        modalDesc: member.description_full.html,
      })),
    }
  })

  return {
    featuredTeam: executive,
    teams,
  }
}

const OurTeamPage = ({ data, pageContext }) => {
  const { prismicTeam } = data
  if (!prismicTeam) return <NoTranslation></NoTranslation>

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(prismicTeam),
    mantelData: extract.mantel(prismicTeam, {
      backgrounds: getPageBgs('our-team'),
      parent: {
        path: localizePath(pageContext.locale, `/${pageContext.parentuid}`),
        text: pageContext.parentTitle,
      },
    }),
    callToAction: extract.callToAction(prismicTeam),
  }
  return <TeamLanding {...hydratedData} {...transformTeam(prismicTeam)} />
}

export default withPreview(OurTeamPage)

export const pageQuery = graphql`
  query TeamBySlug($locale: String!) {
    prismicTeam(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        call_to_action_title {
          text
        }
        call_to_action_content {
          text
        }
        call_to_action_button_text {
          text
        }
        call_to_action_button_url {
          url
        }
        body {
          ... on PrismicTeamBodyTeamSection {
            primary {
              section_title {
                text
              }
              section_description {
                text
              }
              executives
            }
            items {
              name {
                text
              }
              position {
                text
              }
              description_snippet {
                text
              }
              description_full {
                html
              }
              picture {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`
