import React from 'react'
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'
import SubNav from '../../General/SubNav'
import SectionIntro from '../../General/SectionIntro'
import ThreeUp from '../../Layouts/ThreeUp/ThreeUp'
import FourUp from '../../Layouts/FourUp/FourUp'
import PersonCard from '../../General/Cards/PersonCard'
// import NewsletterSignupBanner from '../../General/NewsletterSignupBanner'
import CallToAction from '../../General/CallToAction'

const TeamLanding = ({
  seo,
  subNavItems,
  mantelData,
  featuredTeam,
  teams,
  callToAction,
  newsletterBanner,
}) => (
  <PageWrap seo={seo}>
    <Mantel {...mantelData} />
    <SubNav navItems={subNavItems} />
    <div className="pb-5 pt-2 md:pb-5 lg:pb-8 lg:pt-0">
      {featuredTeam && (
        <div className="mx-auto max-w-2xl">
          <div className="mx-auto mb-4 max-w-lg px-2 lg:px-6">
            <SectionIntro {...featuredTeam.intro} />
          </div>
          <ThreeUp>
            {featuredTeam.members.map((person, i) => (
              <PersonCard key={'featured-member-' + i} {...person} />
            ))}
          </ThreeUp>
        </div>
      )}
      {teams &&
        teams.map((team, i) => (
          <div key={'team-' + i} className="mx-auto max-w-2xl">
            <div className="mx-auto mt-8 mb-4 max-w-lg px-2 lg:px-6">
              <SectionIntro {...team.intro} />
            </div>
            <FourUp>
              {team.members.map((person) => (
                <div key={'member-' + i} className="pb-2">
                  <PersonCard {...person} />
                </div>
              ))}
            </FourUp>
          </div>
        ))}
    </div>
    {callToAction.button.text ? <CallToAction {...callToAction} /> : null}
    {/* <div className="mx-auto max-w-4xl px-5 my-4">
      <NewsletterSignupBanner { ...newsletterBanner } />
    </div> */}
  </PageWrap>
)

export default TeamLanding
