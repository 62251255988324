import React from 'react'

const Plus = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Team" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="team-v1-r0" transform="translate(-453.000000, -1314.000000)" className="fill-current">
        <g id="content" transform="translate(206.000000, 699.000000)">
          <g id="Stacked-Group">
            <g id="Team">
              <g id="entry" transform="translate(0.000000, 284.219479)">
                <g id="silvioMicali" transform="translate(-5.000000, -3.078189)">
                  <path d="M263.414214,343.858711 L269.071068,349.515565 C269.461592,349.906089 269.461592,350.539254 269.071068,350.929778 C268.680544,351.320303 268.047379,351.320303 267.656854,350.929778 L262,345.272924 L256.343146,350.929778 C255.952621,351.320303 255.319456,351.320303 254.928932,350.929778 C254.538408,350.539254 254.538408,349.906089 254.928932,349.515565 L260.585786,343.858711 L254.928932,338.201856 C254.538408,337.811332 254.538408,337.178167 254.928932,336.787643 C255.319456,336.397118 255.952621,336.397118 256.343146,336.787643 L262,342.444497 L267.656854,336.787643 C268.047379,336.397118 268.680544,336.397118 269.071068,336.787643 C269.461592,337.178167 269.461592,337.811332 269.071068,338.201856 L263.414214,343.858711 Z" id="Combined-Shape" transform="translate(262.000000, 343.858711) rotate(-315.000000) translate(-262.000000, -343.858711) "></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Plus
