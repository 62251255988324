import React from 'react'

const Instagram = () => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Team" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="team-v1-r0" transform="translate(-299.000000, -1613.000000)" className="fill-current" fillRule="nonzero">
        <g id="content" transform="translate(206.000000, 699.000000)">
          <g id="Stacked-Group">
            <g id="Team">
              <g id="entry" transform="translate(0.000000, 284.219479)">
                <g id="social" transform="translate(0.000000, 629.780521)">
                  <g id="instagram" transform="translate(93.000000, 0.000000)">
                    <path d="M19.0666667,0 C19.0666667,0 2.58793333,0 2.56666667,0 C1.16013333,0 0.0198,1.16306667 0.00293333333,2.56666667 L0,2.53586667 C0,2.53586667 0,19.4230667 0,19.4333333 C0,20.8516 1.14913333,22 2.56666667,22 C2.57693333,22 19.4333333,22 19.4333333,22 C20.8068667,21.9508667 22,20.8200667 22,19.4333333 C22,19.4230667 22,2.56666667 22,2.56666667 C21.9838667,1.1946 20.4299333,0.0491333333 19.0666667,0 Z M11,6.96666667 C13.2278667,6.96666667 15.0333333,8.77286667 15.0333333,11 C15.0333333,13.2264 13.2278667,15.0333333 11,15.0333333 C8.77286667,15.0333333 6.96666667,13.2264 6.96666667,11 C6.96666667,8.77286667 8.77286667,6.96666667 11,6.96666667 Z M19.0666667,17.6 C19.0666667,18.4096 18.4096,19.0666667 17.6,19.0666667 L4.4,19.0666667 C3.5904,19.0666667 2.93333333,18.4096 2.93333333,17.6 L2.93333333,9.16666667 L4.66326667,9.16666667 C4.49533333,9.74893333 4.4,10.3634667 4.4,11 C4.4,14.6446667 7.35606667,17.6 11,17.6 C14.6461333,17.6 17.6,14.6446667 17.6,11 C17.6,10.3634667 17.5046667,9.74893333 17.3367333,9.16666667 L19.0666667,9.16666667 L19.0637333,17.5714 C19.0637333,17.5816667 19.0666667,17.5897333 19.0666667,17.6 Z M19.0666667,3.67253333 L19.0666667,5.88426667 L19.063,5.88426667 C19.0534667,6.2766 18.7388667,6.59193333 18.3450667,6.59853333 L18.3450667,6.6 L16.1333333,6.6 C15.7336667,6.6 15.4132,6.28026667 15.4036667,5.88353333 L15.4,5.88353333 L15.4,3.67253333 L15.4014667,3.67253333 C15.4014667,3.67033333 15.4,3.66886667 15.4,3.66666667 C15.4,3.26186667 15.7278,2.93333333 16.1333333,2.93333333 L18.3450667,2.93333333 L18.3450667,2.93553333 C18.7447333,2.94213333 19.0666667,3.26553333 19.0666667,3.66666667 C19.0666667,3.66886667 19.0652,3.67033333 19.0652,3.67253333 L19.0666667,3.67253333 Z" id="Camera"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Instagram
