import React from 'react'
import classNames from 'classnames'
import SmartLink from '../SmartLink'

const subNavWrapClasses = `
  justify-center
  py-0
  p-0
  lg:py-3
  mb-8 
  lg:mb-10
  mx-auto
  bg-white
  flex 
  w-full
  flex-col 
  lg:flex-row
  border
  border-grey
  rounded
  shadow-blur
  text-charcoal
`

const subNavClasses = `
  w-full
  items-center
  lg:flex
  md:justify-center 
  md:items-center 
 `

const linkClasses = `
  text-base
  text-center
  leading-none
  w-full
  flex
  flex-col
  font-black
  font-sans
  no-underline
  text-charcoal
  border-grey
  uppercase
  font-black
  lg:py-0
  lg:h-full
  lg:w-1/4
  lg:flex
  md:justity-center 
  lg:aling-center
  lg:items-center
  lg:border-0
  hover:text-green-3
  active:text-green-3
  }
  `

const SubNav = ({ navItems }) =>
  navItems && navItems.length ? (
    <div className="lg:flex lg:justify-center">
      <div className={subNavWrapClasses}>
        {navItems && (
          <div className={subNavClasses}>
            {navItems.map((item, i) => (
              <SmartLink
                key={`subnav-item-${i}`}
                className={classNames(linkClasses, {
                  'border-b': i < navItems.length - 1,
                  'border-b-0': i === navItems.length - 1,
                })}
                to={item.route}
              >
                <span
                  className={classNames(
                    'flex-col w-full px-3 py-3 last:border-0 lg:border-0 lg:flex  lg:text-center md:justify-center md:items-center  lg:w-full lg:leading-loose lg:h-full lg:py-0 md:px-2',

                    {
                      'lg:border-r-0': navItems.length === i + 1,
                      'lg:border-r': navItems.length !== i + 1,
                    }
                  )}
                >
                  {item.text}
                </span>
              </SmartLink>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : null

export default SubNav
